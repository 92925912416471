/**
 * @info    File model
 *
 * @author  Stefan Kość 07.06.2019
 *
 * @deprecated  since new mechanic as FileFactory created (Lukas Laskowski)
 */

export default class FileModel {
  public id!: number | null;
  public mime!: string | null;
  public displayName!: string | null;
  public filename!: string | null;
  public content!: string | null;
  public url?: string | undefined;
  constructor() {
    this.id = null;
    this.mime = null;
    this.displayName = null;
    this.filename = null;
    this.content = null;
    this.url = undefined;
  }
}
